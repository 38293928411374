// Contact.js
import React from 'react';
import './Contact.css'; // Import the custom CSS file for Contact styles and animations

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="contact-content">
        <h2 className="contact-title">Contact Us</h2>
        <p className="contact-description">
          We'd love to hear from you! Reach out to us for any inquiries or to get involved in our mission.
        </p>
        <div className="contact-details">
          <div className="contact-info">
            <i className="fas fa-map-marker-alt contact-icon"></i>
            <p className="contact-info-text" >Address: <a href="https://www.google.com/maps/dir/23.5998808,88.0770985//@23.5998651,88.0070581,12z/data=!4m4!4m3!1m1!4e1!1m0?entry=ttu">Uttarpara, West Bengal, India 712232</a></p>
          </div>
          <div className="contact-info">
            <i className="fas fa-envelope contact-icon"></i>
            <p className="contact-info-text">Email: <a href="mailto:uttarparaanubhuti@gmail.com">uttarparaanubhuti@gmail.com</a></p>
          </div>
          <div className="contact-info">
            <i className="fas fa-phone contact-icon"></i>
            <p className="contact-info-text">Phone no.: <a href="tel:+91 91632 10303">+91 91632 10303</a></p>
          </div>
          <div className="contact-info">
            <i className="fas fa-map-marker-alt contact-icon"></i>
            <p className="contact-info-text" >Contact us on instagram: <a href="https://www.instagram.com/anubhuti_ngo/?fbclid=IwAR3A2FKPTMak1Sy8ot629Uzq3X4MASgkEzGj5Ar_KFbvJDesXXljHu72mMQ">anubhuti_ngo</a></p>
          </div>
          <div className="contact-info">
            <i className="fas fa-phone contact-icon"></i>
            <p>
        Contact us on WhatsApp: <a href="https://wa.me/9163210303">9163210303</a>
      </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
