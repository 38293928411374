import React from 'react';
import HomePage from './Homepage';
import AboutPage from './About';
import Header from './Header';
import ImageSlider from './ImageSlider';
import FacebookPageFeed from './FacebookPageFeed';
import OurWork from './Ourwork';
import Contact from './Contact';
import Footer from './Footer';


const App = () => {
  return (
    <div>
    <Header />
    <HomePage/>
    <ImageSlider/>
    <AboutPage/>
    <OurWork/>
    <FacebookPageFeed pageUrl="https://www.facebook.com/profile.php?id=100090743268820&mibextid=ZbWKwL" />
    <Contact/>
    <Footer/>
    </div>
  );
}

export default App;
