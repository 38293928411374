// OurWork.js
import React from 'react';
import './Ourwork.css'; // Import the custom CSS file for Our Work styles and animations
import project1 from './images/project1.jpg'
import project2 from './images/project2.jpg'
import project3 from './images/project3.jpg'
import project4 from './images/project4.jpg'
import project5 from './images/project5.jpg'
import project6 from './images/project6.jpg'

const OurWork = () => {
  return (
    <div className="our-work-container">
      <div className="our-work-content">
        <h1 className="our-work-title">Our Work</h1>
        <p className="our-work-description">
          Explore some of the impactful projects .
        </p>
        <div className="our-work-gallery">
          {/* Add your work gallery items here */}
          <div className="our-work-item">
            <img src={project1} alt="Project 1" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 1 (Kanaipur Basai Project)</h3>
              <p className="our-work-item-description">Dol festival was celebrated at the Kanaipur Basai handicapped school in Konnagar with vibrant spring decorations. On the morning of the swing, we went to the school grounds on behalf of Uttapara Anubhuti and USP News as part of an effort to help people and do our part in society. They had breakfast together after a morning walk with these 62 students and instructors. 
</p>
            </div>
          </div>
          <div className="our-work-item">
            <img src={project2} alt="Project 2" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 2 ( Deaf Vidyabhavan)</h3>
              <p className="our-work-item-description">The second project is to donate pencils, pencil boxes, books, account books, and drawing supplies to kids and students born into society's marginalized groups. On April 9th, the team went to Deaf Vidyabhavan in Makhla Kashibati, not just for a donation but to give these kids and teachers peace of mind. 
</p>
            </div>
          </div>
          <div className="our-work-item">
            <img src={project3} alt="Project 3" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 3 ( Pet Project)</h3>
              <p className="our-work-item-description">Uttapara Anubhuti arranged to provide water and food for the street dogs. Many pet lovers around the area joined hands and made this initiative successful. The locals as well promised to provide water daily. Uttarpara Anubhuti Welfare Association planned to continue this in the long term as well. 
</p>
            </div>
          </div>
          <div className="our-work-item">
            <img src={project4} alt="Project 4" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 4 ( Traffic Police Project)</h3>
              <p className="our-work-item-description">As the state administration has issued a heat flow warning, Uttapara Anubhuti planned to arrange something for the Traffic Police. To increase morale and mental power, the members of Anubhuti donated ORS, drinking water, and hats to the traffic police officers. Chandannagar Police Commissionerate happily welcomed this social organization initiative.
</p>
            </div>
          </div>
          <div className="our-work-item">
            <img src={project5} alt="Project 5" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 5 ( Free Eye Checkup)  </h3>
              <p className="our-work-item-description">The members of Uttapara Anubhuti arranged for a free eye checkup and glasses for the children with particular needs in Konnagar Basai Area. Apart from free screening, the campaign raised awareness of the importance of eye health diseases and even offered free glasses to people. 
</p>
            </div>
          </div>
          <div className="our-work-item">
            <img src={project6} alt="Project 6" className="our-work-image" />
            <div className="our-work-overlay">
              <h3 className="our-work-item-title">Project 6 ( Cloth Distribution)</h3>
              <p className="our-work-item-description">Uttarpara Anubhuti Welfare Association donated reusable clothes to the children at that school with special needs. All of the people in the group gave things that could be used again from the comfort of their own homes. This made all the group members feel good and made the boys and girls happy as well. 
</p>
            </div>
          </div>
          {/* https://uttarparaanubhuti.co.in/ */}
        </div>
        <h1 className="our-work-title"><a href='https://uttarparaanubhuti.co.in/'>See more of our works here</a></h1>
      </div>
    </div>
  );
};

export default OurWork;










