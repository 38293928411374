import React from 'react';
import './About.css'; // Import the CSS file for the AboutPage component

const AboutPage = () => {
  return (
    <section className="about">
      <div className="about-content">
        <h2 className="about-title">How Did It Start?</h2>
        <p className="about-description">In 2023, a group of social workers who thought every special child should get proper facilities started Uttarpara Anubhuti Welfare Association. Since then, the team has turned her belief into a chance for young people in India to serve. Not only for the poor child but extended to feeding street dogs, relief campaigns in remote villages and urban slums, long-term plantation programs, welfare for senior citizens, etc. It has created a "snowball effect" to help India educate its people and eliminate poverty.</p>
        <h2 className="about-title">What Is Our Aim?</h2>
        <p className="about-description">In the next ten years, Uttarpara Anubhuti Welfare Association will strive to provide holistic education and enable sustainable livelihoods for 5,000 children. We will focus on welfare for special children, Senior Citizens, Sustainable Development for society and nature, a long-term plantation program, a relief campaign for poor people in remote villages and urban slums, relief for pets (mainly street) and sustainable treatments.

Uttarpara Anubhuti Welfare Association will persist in its efforts to make volunteering a national norm. We will promote sustainable development objectives through volunteer programmes that total one billion hours of service. As a platform, Uttarpara Anubhuti Welfare Association will develop at least 20% of volunteers into social change leaders.
</p>
        <ul className="about-highlights">
          <li className="highlight">Vision<br/><br/>

To contribute to the development of a society that is more influential, equitable, and socially conscious</li>
          <li className="highlight">Mission<br/><br/>

To drive social change by nurturing an environment where children and young adults can learn, lead, and flourish.
</li>
          <li className="highlight">Values<br/><br/>

Uttarpara Anubhuti Welfare Association believes that our organization's culture has developed due to our deeply ingrained value system. We hope to instil these values in all of our stakeholders and work.
</li>
        </ul>
        
        
      </div>
    </section>
  );
}

export default AboutPage;
