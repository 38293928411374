import React from 'react';
import './Header.css'; // Import the CSS file for the Header component

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <div className="logo">Uttarpara Anubhuti Welfare Association - Regn No. S0034285( Registered under Societies Registration, W. B.)</div>
        
      </nav>
    </header>
  );
}

export default Header;
