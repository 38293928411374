import React from 'react';

const Footer = () => {
  const footerStyle = {
    backgroundColor: '#333',
    color: '#fff',
    padding: '20px',
    textAlign: 'center',
    fontSize: '14px',
    borderTop: '1px solid #fff',
  };

  return (
    <footer style={footerStyle}>
      <p>&copy; {new Date().getFullYear()} Uttarpara Anubhuti Welfare Association . All rights reserved.</p>
      
    </footer>
  );
};

export default Footer;
