import React, { useState } from 'react';
import './Homepage.css'; // Import the CSS file for the HomePage component
import anulogo from './images/anulogo.jpg'; // Import the logo image file
import donationImage from './images/bank.jpg'; // Import the donation image file

const HomePage = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleModalClick = (event) => {
    // Prevent closing the modal if a click occurred within the modal content area
    event.stopPropagation();
  };

  const openWhatsApp = () => {
    window.open('https://wa.me/9163210303', '_blank');
  };

  return (
    <section className="homepage">
      <img className="homepage-logo" src={anulogo} alt="Logo" onClick={openModal} />
      <div className="homepage-content">
        <h1 className="homepage-title">Uttarpara Anubhuti Welfare Association</h1>
        <p className="homepage-description container">"To bring a smile to every face" is the mission of the Uttarpara Anubhuti Welfare Association. It is best achieved by establishing a society that has been redeemed, regenerated, and advanced, devoid of exploitation, inequality, and disparities. Where people can live in harmony, solidarity, cooperation, and brotherhood, and where each individual's rights are safeguarded. All of these would be realised through the transformation and sustained growth of the Indian society in which they reside.</p>
        <button className="homepage-button" onClick={openModal}>Donate Now</button>
        
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={handleModalClick}>
            <img src={donationImage} alt="Donation Image" className="modal-image" />
            <button className="modal-close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
      <button className="join-button" onClick={openWhatsApp}>Join Us</button>
    </section>
  );
}

export default HomePage;
