import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import is1 from './images/is1.jpg'
import is2 from './images/is2.jpg'
import is3 from './images/is3.jpg'
import is4 from './images/is4.jpg'
import is5 from './images/is5.jpg'
import './ImageSlider.css'; // Import the custom CSS file for ImageSlider styles

const ImageSlider = () => {
  return (
    <Carousel
      autoPlay={true}
      infiniteLoop={true}
      showThumbs={false}
      showStatus={false}
      className="carousel-container"
    >
      <div>
        <img src={is1} alt="Slider Image 1" className="carousel-image" />
      </div>
      <div>
        <img src={is2} alt="Slider Image 1" className="carousel-image" />
      </div>
      <div>
        <img src={is3} alt="Slider Image 1" className="carousel-image" />
      </div>
      <div>
        <img src={is4} alt="Slider Image 1" className="carousel-image" />
      </div>
      <div>
        <img src={is5} alt="Slider Image 1" className="carousel-image" />
      </div>
    </Carousel>
  );
};

export default ImageSlider;
